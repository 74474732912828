<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'

export default {
  methods: {
    ...mapActions(['cerrarSesion','leerToken'])
  },
  components: {

  },
  computed: {
    ...mapGetters(['estaActivo'])
  }
};
</script>
