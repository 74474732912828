import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/inicio',
    name: 'inicio',
    component: () => import(/* webpackChunkName: "about" */ '../views/Inicio.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/instruction',
    name: 'instruction',
    component: () => import(/* webpackChunkName: "about" */ '../views/Instruction.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/survey1',
    name: 'survey1',
    component: () => import(/* webpackChunkName: "about" */ '../views/Survey1.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/survey2',
    name: 'survey2',
    component: () => import(/* webpackChunkName: "about" */ '../views/Survey2.vue'),
    meta: {requireAuth: true}
  },
  {
    path: '/demo',
    name: 'demo',
    component: () => import(/* webpackChunkName: "about" */ '../views/Demo.vue'),
    meta: {requireAuth: true}
  },
  {
    path :'*',
    component: () => import(/* webpackChunkName: "about" */ '../views/Inicio.vue'),
    meta: {requireAuth: true}
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next)=>{
  const rutaProtegida = to.matched.some(record => record.meta.requireAuth);
  if(rutaProtegida && store.state.token === ''){
    next('/')
  }else{
    next();
  }
})

export default router
